/* eslint-disable camelcase */
import { toMoneyFormat } from 'utils'
import { RANGE_LABELS, TIMELINES_CONVERTER } from 'modules/asset/AssetPage/assetPageConstants'
import moment from 'moment'

const hexToRGB = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  if (alpha) return `rgba(${r}, ${g}, ${b}, ${alpha})`
  return `rgb(${r}, ${g}, ${b})`
}

const toSignCorrectDisplayValue = (val, curr) => {
  const currency = typeof curr === 'string' ? curr : '$'
  const value = val.indexOf('-') === 0 ? `-${currency}${val.replace('-', '')}` : `${currency}${val}`
  return value
}

const sortByPropAsc = prop => (a, b) =>
  new Date(a[prop]).getTime() < new Date(b[prop]).getTime()
    ? -1
    : new Date(b[prop]).getTime() < new Date(a[prop]).getTime()
    ? 1
    : 0

const setCoef = (coef, index, divider) => coef + (index + 1) / divider

const setDivider = (name, time) => {
  if (name === 'sixMonthPeriod') {
    return time === 'high' ? 0.140625 : 0.3125
  }

  if (name === 'oneYearPeriod') {
    return time === 'high' ? 0.5625 : 1.25
  }

  if (name === 'fiveYearsPeriod') {
    return time === 'high' ? 10 : 20
  }

  return time === 'high' ? 10 : 20
}

const getTradingVolumeData = data =>
  Object.entries(data).reduce((accumulator, [name]) => {
    const rangeData = (data[name] || []).map(({ date, volume }) => ({
      date,
      volume,
      day: moment
        .utc(date)
        .format(
          `${name === '24h' ? 'hh:mm A' : name === '7d' || name === '30d' ? 'MMM DD' : name === '1y' ? 'MMM' : 'YYYY'}`
        ),
      dayUnique: moment
        .utc(date)
        .format(
          `${name === '24h' ? 'HH' : name === '7d' || name === '30d' ? 'DD' : name === '1y' ? 'MMM-YYYY' : 'YYYY'}`
        ),
      labelToolTip: moment
        .utc(date)
        .format(
          `${
            name === '24h'
              ? 'hh:mm A - MMM DD, YYYY'
              : name === '7d' || name === '30d' || name === '1y'
              ? 'MMM DD, YYYY'
              : 'MMM YYYY'
          }`
        ),
      eachDayUnique: name === '24h' || name === '7d' || name === '30d'
    }))

    const ac = accumulator

    ac[name] = {
      rangeData,
      lastDate: rangeData[rangeData.length - 1]?.date,
      firstDate: rangeData[0]?.date,
      lastValue: rangeData[rangeData.length - 1]?.volume
    }
    return ac
  }, {})

const getSlugData = (assetCompare, name, slug, coef, key) =>
  (assetCompare[name][slug] || []).map(
    ({ price, change, return: returnLocal, date, risk, low = 0, high = 0, volume = 0 }, index) => ({
      date,
      [`priceSlug${key}`]: Number(price)?.toFixed(5) || 0,
      [`returnSlug${key}`]: Number(returnLocal)?.toFixed(5) || change,
      [`riskSlug${key}`]: Number(risk)?.toFixed(5) || 0,
      dayCalculator:
        index === 0
          ? 'Today'
          : moment
              .utc(date)
              .format(
                `${
                  name === 'twentyFourHoursPeriod'
                    ? 'hh:mm A'
                    : name === 'sevenDaysPeriod' || name === 'thirtyDaysPeriod'
                    ? 'MMM DD'
                    : name === 'sixMonthPeriod' || name === 'ytd' || name === 'oneYearPeriod'
                    ? 'MMM'
                    : 'YYYY'
                }`
              ),
      day: moment
        .utc(date)
        .format(
          `${
            name === 'twentyFourHoursPeriod'
              ? 'h:mm A'
              : name === 'sevenDaysPeriod' || name === 'thirtyDaysPeriod'
              ? 'MMM DD'
              : name === 'sixMonthPeriod' || name === 'ytd' || name === 'oneYearPeriod'
              ? 'MMM'
              : 'YYYY'
          }`
        ),
      dayUnique: moment
        .utc(date)
        .format(
          `${
            name === 'twentyFourHoursPeriod'
              ? 'h:mm A'
              : name === 'sevenDaysPeriod' || name === 'thirtyDaysPeriod'
              ? 'MMM DD'
              : name === 'sixMonthPeriod' || name === 'ytd' || name === 'oneYearPeriod'
              ? 'MMM-YYYY'
              : 'YYYY'
          }`
        ),
      dayShort: `${name === 'fiveYearsPeriod' || name === 'tenYearsPeriod' ? `'` : ''}${moment
        .utc(date)
        .format(`${name === 'sixMonthPeriod' || name === 'ytd' || name === 'oneYearPeriod' ? 'MMM' : 'YY'}`)}`,
      labelToolTip: moment
        .utc(date)
        .format(
          `${
            name === 'twentyFourHoursPeriod'
              ? 'h:mm A - MMM DD, YYYY'
              : name === 'sevenDaysPeriod' || name === 'thirtyDaysPeriod' || name === 'ytd' || name === 'oneYearPeriod'
              ? 'MMM DD, YYYY'
              : 'MMM YYYY'
          }`
        ),
      labelReturnsToolTip: moment
        .utc(date)
        .format(
          `${
            name === 'twentyFourHoursPeriod'
              ? 'h:mm A - MMM DD, YYYY'
              : name === 'sevenDaysPeriod' ||
                name === 'ytd' ||
                name === 'thirtyDaysPeriod' ||
                name === 'oneYearPeriod' ||
                name === 'sixMonthPeriod'
              ? 'MMM DD, YYYY'
              : 'MMM YYYY'
          }`
        ),
      eachDayUnique: name === 'twentyFourHoursPeriod' || name === 'sevenDaysPeriod' || name === 'thirtyDaysPeriod',
      [`indexSlug${key}`]: index,
      [`priceRangeSlug${key}`]: [low, high],
      [`priceRangeCoefSlug${key}`]: [
        low * setCoef(coef, index, setDivider(name, 'low')),
        high * setCoef(coef, index, setDivider(name, 'high'))
      ],
      [`volumeSlug${key}`]: volume
    })
  )

const getParsedCompareManyData = (assetCompare, assetslugs) =>
  Object.entries(assetCompare).reduce((accumulator, [name]) => {
    const slugs = assetslugs.reduce((ac, assetSlug, assetKey) => {
      const accum = ac
      accum.push(...getSlugData(assetCompare, name, assetSlug, 1, assetKey + 1))
      return accum
    }, [])

    const doubledData = []

    const doubled = slugs.filter(
      ({ date }) => slugs.filter(({ date: dateLocal }) => date === dateLocal).length === assetslugs.length
    )

    const single =
      slugs.filter(({ date }) => slugs.filter(({ date: dateLocal }) => date === dateLocal).length === 1) || []

    doubled.forEach(val => {
      const { date = '' } = val
      if (doubledData?.find(data => date === data?.date)) {
        const index = doubled.findIndex(data => date === data?.date)
        doubledData[index] = { ...doubledData[index], ...val }
      } else doubledData.push(val)
    })

    const rangeData =
      [...(doubledData.length ? doubledData : single)].filter(item => item).sort(sortByPropAsc('date')) || []

    const highlights = Object.entries(assetCompare[name].highlights || {}).reduce((highlightsTotal, [key, value]) => {
      const ac = highlightsTotal
      ac[key] = value
      return ac
    }, {})

    const ac = accumulator
    ac[name] = {
      rangeData,
      lastDate: rangeData[rangeData.length - 1]?.date,
      firstDate: rangeData[0]?.date,
      nameLong: name,
      slugs,
      doubled,
      single,
      doubledData,
      priceFirst:
        assetslugs?.reduce((prices, assetSlug, key) => {
          const acc = prices
          acc[`priceFirstSlug${key + 1}`] = assetCompare[name]?.[assetSlug]?.[0]?.price || 1
          return acc
        }, {}) || {},
      priceSlugFirst: rangeData[0]?.priceSlug1,
      priceSlugSecond: rangeData[0]?.priceSlug2,
      highlights
    }
    return ac
  }, {})

const getParsedCompareData = (assetCompare, slugFirst, slugSecond, coef = 1) =>
  Object.entries(assetCompare).reduce((accumulator, [name]) => {
    const highlights = Object.entries(assetCompare[name].highlights || {}).reduce((highlightsTotal, [key, value]) => {
      const ac = highlightsTotal
      ac[key === slugFirst ? 'slug1' : 'slug2'] = value
      return ac
    }, {})

    const slug1 = getSlugData(assetCompare, name, slugFirst, coef, 1)

    const slug2 = getSlugData(assetCompare, name, slugSecond, coef, 2)

    const slugs = [...slug1, ...slug2]
    const doubledData = []

    const doubled = slugs.filter(({ date }) => slugs.filter(({ date: dateLocal }) => date === dateLocal).length === 2)

    const single =
      slugs.filter(({ date }) => slugs.filter(({ date: dateLocal }) => date === dateLocal).length === 1) || []

    doubled.forEach(val => {
      const { date = '' } = val
      if (doubledData?.find(data => date === data?.date)) {
        const index = doubled.findIndex(data => date === data?.date)
        doubledData[index] = { ...doubledData[index], ...val }
      } else doubledData.push(val)
    })

    const rangeData =
      [...(doubledData.length ? doubledData : single)].filter(item => item).sort(sortByPropAsc('date')) || []

    const isExist = val => val !== undefined

    const getMax = (data, value1, value2) =>
      Math.max(
        ...[
          ...Object.values(data)
            .filter(item => isExist(item[value1]))
            .map(item => item[value1]),
          ...Object.values(data)
            .filter(item => isExist(item[value2]))
            .map(item => item[value2])
        ]
      )

    const getMin = (data, value1, value2) =>
      Math.min(
        ...[
          ...Object.values(data)
            .filter(item => isExist(item[value1]))
            .map(item => item[value1]),
          ...Object.values(data)
            .filter(item => isExist(item[value2]))
            .map(item => item[value2])
        ]
      )

    const ac = accumulator
    ac[name] = {
      rangeDataTotal: rangeData,
      highlights,
      lastDate: rangeData[rangeData.length - 1]?.date,
      firstDate: rangeData[0]?.date,
      min: getMin(rangeData, 'priceSlug1', 'priceSlug2'),
      max: getMax(rangeData, 'priceSlug1', 'priceSlug2'),
      priceCoef: getMax(rangeData, 'priceSlug1', 'priceSlug2') / getMin(rangeData, 'priceSlug1', 'priceSlug2'),
      synced: single.length === 0,
      nameLong: name,
      rangeData,
      priceSlugFirst: rangeData[0]?.priceSlug1,
      priceSlugSecond: rangeData[0]?.priceSlug2,
      slugFirst,
      slugSecond,
      doubled,
      single,
      coef:
        rangeData[rangeData.length - 1]?.priceRangeCoefSlug1?.length > 1 &&
        rangeData[rangeData.length - 1]?.priceRangeCoefSlug2?.length > 1
          ? Math.max(
              ...[
                Number(rangeData[rangeData.length - 1]?.priceRangeCoefSlug1[1]) /
                  Number(rangeData[rangeData.length - 1]?.priceRangeSlug1[1]),
                Number(rangeData[rangeData.length - 1]?.priceRangeCoefSlug2[1]) /
                  Number(rangeData[rangeData.length - 1]?.priceRangeSlug2[1])
              ]
            )
          : 1
    }
    return ac
  }, {})

const toFilteredGraphData = (filterByValue, dataValue) => {
  const filteredDate = dataValue[filterByValue]
  const {
    rangeData,
    min,
    max,
    firstDate,
    lastDate,
    rangeDataTotal,
    lastDateRisks,
    priceCoef,
    priceSlugFirst,
    priceSlugSecond,
    slugFirst,
    slugSecond,
    coef
  } = filteredDate || {}

  return {
    rangeData,
    firstDate,
    lastDate,
    min,
    max,
    rangeDataTotal,
    lastDateRisks,
    priceCoef,
    priceSlugFirst,
    priceSlugSecond,
    slugFirst,
    slugSecond,
    coef
  }
}

const toFilteredGraphDataForReturns = (filterByValue, dataValue) => {
  const filteredDate = dataValue[filterByValue]
  const {
    rangeData,
    min,
    max,
    rangeDataTotal,
    lastDateRisks,
    priceCoef,
    priceSlugFirst,
    priceSlugSecond,
    slugFirst,
    slugSecond,
    coef
  } = filteredDate || {}

  const noGapsRangeData = rangeData.filter(el => el.priceSlug2 && el.priceSlug1)
  const noGapsRangeDataTotal = rangeDataTotal.filter(el => el.priceSlug2 && el.priceSlug1)

  return {
    rangeData: noGapsRangeData,
    firstDate: noGapsRangeData[0]?.date,
    lastDate: noGapsRangeData[noGapsRangeData.length - 1]?.date,
    min,
    max,
    rangeDataTotal: noGapsRangeDataTotal,
    lastDateRisks,
    priceCoef,
    priceSlugFirst,
    priceSlugSecond,
    slugFirst,
    slugSecond,
    coef
  }
}

const toFilteredHighLowData = (filterByValue, dataValue) => {
  const filteredDate = dataValue[filterByValue]
  const { highlights } = filteredDate || {}
  const { slug1, slug2 } = highlights || {}
  return { slugFirst: slug1, slugSecond: slug2 }
}
const getRiskRangeName = label => RANGE_LABELS.find(({ name }) => name === label)?.name || ''

const getRiskRangeShortName = label => RANGE_LABELS.find(({ name }) => name === label)?.shortName || ''

const getRiskRange = risk =>
  risk <= 25
    ? getRiskRangeName('Low')
    : risk <= 65
    ? getRiskRangeName('Moderate')
    : risk <= 85
    ? getRiskRangeName('High')
    : getRiskRangeName('Very High')

// hack for recharts custom YAxis with values close to risk range values
const getRiskRangeLabels = risk =>
  risk <= 27
    ? getRiskRangeShortName('Low')
    : risk <= 63
    ? getRiskRangeShortName('Moderate')
    : risk <= 81
    ? getRiskRangeShortName('High')
    : getRiskRangeShortName('Very High')

const getCoef = coef => Math.floor(+coef.toFixed(0) / 600) * 20

const isCorrectSlugs = (data, slug1, slug2) => data?.sixMonthPeriod[slug1] && data?.sixMonthPeriod[slug2]

const getCalculatorData = (baseData, contribution, compareData) => {
  // Get compare element separate
  const [compare1, compare2] = compareData
  // Get data from baseData based on compare compareData names
  const rawCompareData = { [compare1.slug]: baseData[compare1.name], [compare2.slug]: baseData[compare2.name] }

  // Get full ten years data
  const tenYearsPeriod = Object.entries(rawCompareData).reduce(
    (acc, [key, val]) => ({
      ...acc,
      [key]: val
        ? Object.entries(val).reduce(
            (period, [date, { projected_low, projected_high }]) => [
              ...period,
              {
                lowCoeff: projected_low,
                highCoeff: projected_high,
                date
              }
            ],
            []
          )
        : null
    }),
    {}
  )

  // Convert raw data to valid graph data
  const structuredData = {
    sixMonthPeriod: Object.entries(tenYearsPeriod).reduce(
      (acc, [key, val]) => ({ ...acc, [key]: val?.slice(0, 6) || [] }),
      {}
    ),
    oneYearPeriod: Object.entries(tenYearsPeriod).reduce(
      (acc, [key, val]) => ({ ...acc, [key]: val?.slice(0, 12) || [] }),
      {}
    ),
    fiveYearsPeriod: Object.entries(tenYearsPeriod).reduce(
      (acc, [key, val]) => ({ ...acc, [key]: val?.slice(0, 60) || [] }),
      {}
    ),
    tenYearsPeriod
  }

  // Build new data for the graph
  const finalData = Object.entries(structuredData).reduce(
    (timeAcc, [timeKey, timeData]) => ({
      ...timeAcc,

      [timeKey]: Object.entries(timeData).reduce(
        (classAcc, [classKey, classData]) => ({
          ...classAcc,

          [classKey]:
            classData?.reduce((accData, { lowCoeff, highCoeff, date }) => {
              const low = lowCoeff * contribution
              const high = highCoeff * contribution

              return [...accData, { low, high, date }]
            }, []) || []
        }),
        {}
      )
    }),
    {}
  )

  return finalData
}

const getCalculatorTotal = (base, total) => {
  const baseLast = base[base.length - 1] || { high: 0, low: 0 }
  // Get high
  const high = +baseLast.high.toFixed(0)
  const highPercent = +(((baseLast.high - total) / total) * 100).toFixed(0)
  // Get low
  const low = +baseLast.low.toFixed(0)
  const lowPercent = +(((baseLast.low - total) / total) * 100).toFixed(0)

  return {
    total: `$${toMoneyFormat(total, 0)}`,
    high: `$${toMoneyFormat(high, 0)}`,
    highPercent: `${highPercent > 0 ? '+' : ''}${highPercent || 0}%`,
    low: `$${toMoneyFormat(low, 0)}`,
    lowPercent: `${lowPercent > 0 ? '+' : ''}${lowPercent || 0}%`
  }
}

const getReturnPercentValueSlug = (priceSlugCurrent, priceSlugFirst) =>
  +(((priceSlugCurrent - priceSlugFirst) / priceSlugFirst) * 100).toFixed(2)

const getPriceFirstPercentage = (priceLast, priceFirst) => ((priceLast - priceFirst) / priceFirst) * 100

const getUniqueMiddleLabel = (dayUnique, index, uniqueDataCounter) => {
  const uniqueDataDay = uniqueDataCounter.find(({ dayUnique: localDay }) => dayUnique === localDay)

  if (uniqueDataDay) {
    const { count, startIndex, eachDayUnique } = uniqueDataDay
    // for 24H, 7D, 30D
    if (eachDayUnique) return true
    // for small dates
    if (count === 1 && uniqueDataCounter?.length < 6) return true
    // for large dates
    const middleIndex = startIndex + Math.round(count / 2)
    return middleIndex === index
  }

  return false
}

const getPairLabel = (dayUnique, uniqueDataCounter) => {
  const uniqueDataDay = uniqueDataCounter.find(({ dayUnique: localDay }) => dayUnique === localDay)
  if (uniqueDataDay) {
    const { pair } = uniqueDataDay
    return pair
  }

  return false
}

const getEachFifthabel = (dayUnique, uniqueDataCounter) => {
  const uniqueDataDay = uniqueDataCounter.find(({ dayUnique: localDay }) => dayUnique === localDay)
  if (uniqueDataDay) {
    const { eachFifth } = uniqueDataDay
    return eachFifth
  }

  return false
}

const getEachThirdabel = (dayUnique, uniqueDataCounter) => {
  const uniqueDataDay = uniqueDataCounter.find(({ dayUnique: localDay }) => dayUnique === localDay)
  if (uniqueDataDay) {
    const { eachThird } = uniqueDataDay
    return eachThird
  }

  return false
}

const toFilterLabels = (data, short = false) => {
  let markerCount = ''
  let uniqueCount = ''
  if (data) {
    const uniqueData = data?.map(({ dayUnique }) => dayUnique)
    const uniqueDataFlat = [...new Set(uniqueData)]
    const lastUnique = uniqueData[uniqueData.length - 1]

    const uniqueDataCounter = data
      .reduce((counter, { day, dayUnique, eachDayUnique }, index) => {
        const acc = counter
        const unique = dayUnique !== markerCount
        markerCount = dayUnique

        if (unique)
          acc.push({
            day,
            dayUnique,
            count: 1,
            startIndex: index,
            eachDayUnique
          })
        else {
          const uniqueDataDay = acc.find(({ dayUnique: localDay }) => dayUnique === localDay)
          if (uniqueDataDay) uniqueDataDay.count += 1
        }

        return acc
      }, [])
      .map((dataCounter, index) => ({
        pair:
          uniqueDataFlat.length < 6 ? true : uniqueDataFlat.length % 2 === 1 ? (index + 1) % 2 === 1 : index % 2 === 1,
        ...dataCounter
      }))
      .slice(0)
      .reverse()
      .map((dataCounter, index) => ({
        eachThird: uniqueDataFlat.length < 6 ? true : (index + 3) % 3 === 0,
        eachFifth: uniqueDataFlat.length < 6 ? true : (index + 5) % 5 === 0,
        ...dataCounter
      }))
      .slice(0)
      .reverse()

    return data.map((item, index) => {
      const { dayUnique } = item
      const last = dayUnique === lastUnique && index === data.length - 1
      const unique = getUniqueMiddleLabel(dayUnique, index, uniqueDataCounter)
      const pair = getPairLabel(dayUnique, uniqueDataCounter)
      const eachThird = getEachThirdabel(dayUnique, uniqueDataCounter)
      const eachFifth = getEachFifthabel(dayUnique, uniqueDataCounter)
      const uniqueShort = dayUnique !== uniqueCount
      uniqueCount = dayUnique

      return {
        ...item,
        key: index,
        unique: !short ? unique : uniqueShort,
        last,
        pair,
        eachThird,
        eachFifth
      }
    })
  }
}

const rangeMixMaxRisks = rangeDataLabels => {
  const rangeMixMax = rangeDataLabels.reduce((risks, { riskSlug1, riskSlug2 }) => {
    const acc = risks
    acc.push(riskSlug1, riskSlug2)
    return acc
  }, [])

  const rangeUnique = [...new Set(rangeMixMax)]
  return [Math.min(...rangeUnique) * 100, Math.max(...rangeUnique) * 100]
}

const getTimeAgoValue = date => {
  if (!date) return ''

  const closeDate = new Date(date)
  const nowDate = new Date()
  const difference = nowDate.getTime() - closeDate.getTime()

  const hoursAgoNumber = (difference / (1000 * 3600)).toFixed()
  const daysAgoNumber = (difference / (1000 * 3600 * 24)).toFixed()

  if (+hoursAgoNumber === 0 && +daysAgoNumber === 0) return 'Recently'
  // If less than 1 day return hours amount
  if (+daysAgoNumber < 1) return +hoursAgoNumber === 1 ? `${hoursAgoNumber} hour` : `${hoursAgoNumber} hours`
  // If days more than 1 return days amount
  if (+daysAgoNumber !== 0) return +daysAgoNumber === 1 ? `${daysAgoNumber} day` : `${daysAgoNumber} days`

  return ''
}

const toMoneySymbolsFormat = labelValue =>
  // Twelve Zeroes for Trillions
  Math.abs(Number(labelValue)) >= 1.0e12
    ? `${(Math.abs(Number(labelValue)) / 1.0e12)?.toFixed(2)}T`
    : // Nine Zeroes for Billions
    Math.abs(Number(labelValue)) >= 1.0e9
    ? `${(Math.abs(Number(labelValue)) / 1.0e9)?.toFixed(2)}B`
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? `${(Math.abs(Number(labelValue)) / 1.0e6)?.toFixed(2)}M`
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? `${Math.abs(Number(labelValue)) / (1.0e3).toFixed(2)}K`
    : Math.abs(Number(labelValue))

const toFilterTimeFrames = (slugFirst, slugSecond, assetCompare, timelines) =>
  timelines
    .map(timeline => {
      const minLengthSlugFirst = assetCompare[TIMELINES_CONVERTER[timeline]][slugFirst]?.length || 0
      const minLengthSlugSecond = assetCompare[TIMELINES_CONVERTER[timeline]][slugSecond]?.length || 0
      return {
        title: timeline,
        minLength: Math.min(minLengthSlugFirst, minLengthSlugSecond)
      }
    })
    .map(timeframe => {
      const { title, minLength } = timeframe
      let display = true

      switch (title) {
        case '1Y':
          display = !(minLength < 180)
          break

        case '5Y':
          display = !(minLength < 30)
          break

        case 'YTD':
          display = !(minLength < 50)
          break

        case '10Y':
          display = !(minLength < 60)
          break

        default:
          display = true
      }
      return {
        ...timeframe,
        display
      }
    })
    .filter(({ display }) => display)
    .reduce((ac, { title }) => {
      ac.push(title)
      return ac
    }, [])

const getSumByProp = (data, prop) => data.reduce((ac, asset) => ac + (asset?.[prop] || 0), 0)

const getInitFilterCheckBoxes = (blocks, isLogged, isAuthChecked) =>
  blocks.reduce((ac, { slug, unauthLock, default: defaultProp }) => {
    const accum = ac
    accum[slug] = {
      checked: !unauthLock ? defaultProp : isLogged && isAuthChecked && defaultProp
    }
    return accum
  }, {})

const getInitFilterRadioButtons = (blocks, isLogged, isAuthChecked) => {
  let first = false
  return blocks.reduce((ac, { slug, unauthLock }) => {
    const checked = !unauthLock ? true : isLogged && isAuthChecked

    const accum = ac
    accum[slug] = {
      checked: first ? false : checked
    }

    if (checked) {
      first = true
    }

    return accum
  }, {})
}

const getInitFilterRadioButtonsEmpty = blocks =>
  blocks.reduce((ac, { slug }) => {
    const accum = ac
    accum[slug] = {
      checked: false
    }

    return accum
  }, {})

const setCurrentRadio = (radioButtons, slugChange) =>
  Object.entries(radioButtons).reduce((ac, [key, val]) => {
    const accum = ac
    accum[key] = {
      ...val,
      checked: slugChange === key
    }
    return accum
  }, {})

const isDisplayTradingVolumePoint = (
  index,
  key,
  unique,
  last,
  pair,
  eachThird,
  eachFifth,
  screenWidth,
  chartTimeline
) => {
  if (index === key && unique) {
    if (chartTimeline === '24H') {
      if (screenWidth > 601 ? eachThird : eachFifth) {
        return true
      }
      return false
    }

    if (chartTimeline === '7D') {
      if (screenWidth > 601 ? true : pair) {
        return true
      }
      return false
    }

    if (chartTimeline === '30D') {
      if (eachFifth) {
        return true
      }
      return false
    }

    if (chartTimeline === '6M' || chartTimeline === '1Y') {
      if (screenWidth < 601 ? pair : true) {
        return true
      }
      return false
    }

    if (chartTimeline === '5Y') {
      if (screenWidth < 601 ? pair : true) {
        return true
      }
      return false
    }

    if (chartTimeline === '10Y') {
      if (screenWidth < 601 ? eachThird : true) {
        return true
      }
      return false
    }

    if (chartTimeline === 'ALL') {
      return true
    }
  }

  return false
}

const getCompareManyBlocksSlugs = radioButtons =>
  Object.entries(radioButtons).reduce((accumulator, [name, { checked }]) => {
    const ac = accumulator
    if (checked) ac.push(name)
    return ac
  }, [])

const getCompareDataMany = (radioButtons, returnsChartBlocks, slugCompare) => {
  const compareManyBlocksSlugs = getCompareManyBlocksSlugs(radioButtons)
  return getParsedCompareManyData(returnsChartBlocks, [...compareManyBlocksSlugs, slugCompare])
}

const toFilteredHighLowDataMany = (returnsChartBlocks, chartTimeline, activeSlug, slugCompare) => {
  const highlights = returnsChartBlocks[TIMELINES_CONVERTER[chartTimeline]]?.highlights

  return {
    slugFirst: { ...highlights[activeSlug] },
    slugSecond: { ...highlights[slugCompare] }
  }
}

const getCalcDeltas = data => {
  const rangeSlugs = data.reduce((risks, { priceRangeSlug1, priceRangeSlug2 }) => {
    const acc = risks
    acc.push(priceRangeSlug1 || [], priceRangeSlug2 || [])
    return acc
  }, [])

  const rangeUniqueSlugs = [...new Set(rangeSlugs?.flat())]

  const min = Math.min(...rangeUniqueSlugs)
  const max = Math.max(...rangeUniqueSlugs)

  const rangeCoefSlugs = data.reduce((risks, { priceRangeCoefSlug1, priceRangeCoefSlug2 }) => {
    const acc = risks
    acc.push(priceRangeCoefSlug1 || [], priceRangeCoefSlug2 || [])
    return acc
  }, [])

  const rangeUniqueCoefSlugs = [...new Set(rangeCoefSlugs?.flat())]

  const minCoefSlugs = Math.min(...rangeUniqueCoefSlugs)
  const maxCoefSlugs = Math.max(...rangeUniqueCoefSlugs)
  // @Ruslan
  // eslint-disable-next-line no-unsafe-optional-chaining
  return (maxCoefSlugs - minCoefSlugs)?.toFixed(0) / (max - min)?.toFixed(0)
}

export {
  hexToRGB,
  toSignCorrectDisplayValue,
  getParsedCompareData,
  toFilteredGraphDataForReturns,
  toFilteredGraphData,
  toFilteredHighLowData,
  getRiskRange,
  getRiskRangeLabels,
  getCalculatorData,
  getCalculatorTotal,
  getCoef,
  isCorrectSlugs,
  getReturnPercentValueSlug,
  getPriceFirstPercentage,
  toFilterLabels,
  rangeMixMaxRisks,
  getTimeAgoValue,
  toMoneySymbolsFormat,
  getTradingVolumeData,
  toFilterTimeFrames,
  getSumByProp,
  getParsedCompareManyData,
  getInitFilterCheckBoxes,
  getInitFilterRadioButtons,
  getInitFilterRadioButtonsEmpty,
  setCurrentRadio,
  isDisplayTradingVolumePoint,
  getCompareDataMany,
  toFilteredHighLowDataMany,
  getCalcDeltas
}
