const TIMELINES = ['6M', 'YTD', '1Y', '5Y', '10Y']

const TIMELINES_CONVERTER = {
  '24H': 'twentyFourHoursPeriod',
  '7D': 'sevenDaysPeriod',
  '30D': 'thirtyDaysPeriod',
  '6M': 'sixMonthPeriod',
  YTD: 'ytd',
  '1Y': 'oneYearPeriod',
  '5Y': 'fiveYearsPeriod',
  '10Y': 'tenYearsPeriod'
}

const TIMELINES_CMS_CONVERTER = {
  twentyFourHoursPeriod: '1d',
  sevenDaysPeriod: '1w',
  thirtyDaysPeriod: '1m',
  sixMonthPeriod: '6m',
  oneYearPeriod: '1y',
  ytd: 'ytd',
  fiveYearsPeriod: '5y',
  tenYearsPeriod: '10y'
}

const TIMELINES_RETURNS_CONVERTER = {
  twentyFourHoursPeriod: '24H',
  sevenDaysPeriod: '7D',
  thirtyDaysPeriod: '30D',
  sixMonthPeriod: '6M',
  oneYearPeriod: '1Y',
  ytd: 'YTD',
  fiveYearsPeriod: '5Y',
  tenYearsPeriod: '10Y'
}

const TIMELINES_IN_NUMBER = {
  '6M': 6,
  '1Y': 12,
  '5Y': 60,
  '10Y': 120
}

const TIMELINES_HIGHLOW_CONVERTER = {
  '24H': '24h',
  '7D': '7d',
  '30D': '30d',
  '6M': '6m',
  YTD: 'ytd',
  '1Y': '1yr',
  '5Y': '5yr',
  '10Y': '10yr'
}

const ASSET_CLASSES = [
  // Public Markets
  { name: 'Bonds', slug: 'bond' },
  { name: 'Robo Advisor', slug: 'robo-advisor' },
  { name: 'S&P 500', slug: 'spx', shortName: 'S&P' },
  { name: 'Gold', slug: 'gold' },
  { name: 'Silver', slug: 'silver' },
  { name: 'Platinum', slug: 'platinum' },
  { name: 'Crude Oil', slug: 'crude-oil' },
  // Web3
  { name: 'Bitcoin', slug: 'bitcoin' },
  { name: 'Ethereum', slug: 'ethereum' },
  { name: 'Tether', slug: 'tether' },
  { name: 'Solana', slug: 'solana' },
  { name: 'Cardano', slug: 'cardano' },
  { name: 'Ripple', slug: 'ripple-xrp' },
  { name: 'Doge', slug: 'doge-usd' },
  { name: 'DeFi', slug: 'defi' },
  { name: 'Metaverse', slug: 'metaverse' },
  { name: 'NFTs', slug: 'nfts' },
  { name: 'Polkadot', slug: 'polkadot' },
  { name: 'Litecoin', slug: 'litecoin' },
  // Collectibles
  { name: 'Fine Art', slug: 'contemporary' },
  { name: 'Modern', slug: 'modern-art' },
  { name: 'Nineteenth Century', slug: 'nineteenth-century' },
  { name: 'Old Masters', slug: 'old-masters' },
  { name: 'Post War', slug: 'post-war' },
  { name: 'Drawing', slug: 'drawing' },
  { name: 'Sculpture', slug: 'sculpture' },
  { name: 'Painting', slug: 'drawing' },
  { name: 'Photography', slug: 'photography' },
  { name: 'Print', slug: 'print' },
  { name: 'Global Index in USD', slug: 'index-usd' },
  { name: 'Wine', slug: 'wine-100' },
  { name: 'Wine', slug: 'wine-1000' },
  { name: 'Sports Cards', slug: 'sport-cards' },
  { name: 'Whiskey', slug: 'whiskey' },
  { name: 'Luxury Watches', slug: 'watches' },
  { name: 'Cars', slug: 'cars' },
  // Real Estate
  { name: 'Farmland', slug: 'farmland' },
  { name: 'Real Estate', slug: 'real-estate' },
  // Startups
  { name: 'Cannabis', slug: 'cannabis' },
  { name: 'Consumer Goods', slug: 'consumer-goods' },
  { name: 'Bitcoin', slug: 'crypto' },
  { name: 'Education', slug: 'education' },
  { name: 'Fashion', slug: 'fashion' },
  { name: 'Healthcare', slug: 'healthcare' },
  { name: 'Wellness & Beauty', slug: 'wellness-beauty' },
  { name: 'Transportation', slug: 'transportation' },
  { name: 'Information Technology', slug: 'information-technology' },
  { name: 'Finance', slug: 'finance' },
  { name: 'Media Entertainment', slug: 'media-entertainment' },
  { name: 'Food & Beverage', slug: 'food-beverage' },
  // Music
  { name: 'Music Royalties', slug: 'music-royalties' },
  // No Gropus
  { name: 'High Yield Savings', slug: 'high-yield-savings' },
  { name: 'IRA', slug: 'ira' },
  { name: 'Lending', slug: 'lending' },
  { name: 'Startups', slug: 'startups' },
  { name: 'Collectibles', slug: 'collectibles' },
  { name: 'Cash', slug: 'cash' },
  { name: 'Sports Memorabilia', slug: 'sports-memorabilia' }
]

const RISK_DROPDOWN = [
  { name: 'S&P 500', slug: 'spx' },
  { name: 'Farmland', slug: 'farmland' },
  { name: 'Real Estate', slug: 'real-estate' },
  { name: 'Gold', slug: 'gold' },
  { name: 'Sports Cards', slug: 'sport-cards' },
  { name: 'Wine', slug: 'wine-1000' },
  { name: 'Art', slug: 'contemporary' },
  { name: 'Bitcoin', slug: 'bitcoin' },
  { name: 'Robo Advisor', slug: 'robo-advisor' }
]

const CALCULATOR_DROPDOWN = [
  { name: 'Bond', slug: 'bond' },
  { name: 'Gold', slug: 'gold' },
  { name: 'S&P 500', slug: 'spx' },
  { name: 'Bitcoin', slug: 'bitcoin' },
  { name: 'Ethereum', slug: 'ethereum' },
  { name: 'Sports Cards', slug: 'sport-cards' },
  { name: 'Wine', slug: 'wine-1000' },
  { name: 'Farmland', slug: 'farmland' },
  { name: 'Real Estate', slug: 'real-estate' },
  { name: 'Art', slug: 'contemporary' },
  { name: 'Robo Advisor', slug: 'robo-advisor' },
  { name: 'Information Technology', slug: 'information-technology' },
  { name: 'Silver', slug: 'silver' },
  { name: 'Platinum', slug: 'platinum' },
  { name: 'Crude Oil', slug: 'crude-oil' },
  { name: 'Cardano', slug: 'cardano' },
  { name: 'Ripple XRP', slug: 'ripple-xrp' },
  { name: 'Solana', slug: 'solana' },
  { name: 'Polkadot', slug: 'polkadot' }
]

const RANGE_LABELS = [
  { name: 'Low', shortName: 'Low', index: 0 },
  { name: 'Moderate', shortName: 'Mod', index: 1 },
  { name: 'High', shortName: 'High', index: 2 },
  { name: 'Very High', shortName: 'V.High', index: 3 }
]

const TIMELINES_TRADING = ['24H', '7D', '30D', '1Y', 'ALL']

const TIMELINES_TRADING_CONVERTER = {
  '24H': '24h',
  '7D': '7d',
  '30D': '30d',
  '1Y': '1y',
  ALL: 'all'
}

const BOARD_DATA = {
  marketplaces: {
    columns: 3,
    column1: 'Market',
    column2: 'Traders',
    column3: 'Volume'
  },
  assets: {
    columns: 2,
    column1: 'NFT',
    column2: '',
    column3: 'Price'
  },
  collections: {
    columns: 3,
    column1: 'Collection',
    column2: 'Traders',
    column3: 'Volume'
  }
}

const URLS = {
  WaysToInvest: {
    id: 'ways-invest'
  },
  ExploreAssets: {
    id: 'explore-assets'
  }
}

const COLORS = [
  {
    color: 'teal',
    hex: '#14aea5'
  },
  {
    color: 'indigo',
    hex: '#2620a6'
  },
  {
    color: 'light-blue',
    hex: '#bbd9e4'
  },
  {
    color: 'grape',
    hex: '#6525c2'
  },
  {
    color: 'green',
    hex: '#00993f'
  },
  {
    color: 'orange',
    hex: '#ff9700'
  },
  {
    color: 'yellow',
    hex: '#f7d633'
  },
  {
    color: 'pink-red',
    hex: '#e62b32'
  },
  {
    color: 'black4',
    hex: '#76767a'
  }
]

export {
  TIMELINES,
  TIMELINES_CONVERTER,
  TIMELINES_IN_NUMBER,
  TIMELINES_HIGHLOW_CONVERTER,
  ASSET_CLASSES,
  RISK_DROPDOWN,
  CALCULATOR_DROPDOWN,
  RANGE_LABELS,
  TIMELINES_TRADING,
  BOARD_DATA,
  TIMELINES_TRADING_CONVERTER,
  URLS,
  COLORS,
  TIMELINES_CMS_CONVERTER,
  TIMELINES_RETURNS_CONVERTER
}
